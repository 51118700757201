import React from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import classNames from "classnames"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const styles = createStyles({
  about: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "100px 40px",
    "@media (max-width: 599.95px)": {
      padding: "40px 20px",
    },
  },
  aboutContent: {
    maxWidth: 840,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
    color: "#898989",
  },
  backgroundImage: {
    position: "absolute !important" as "absolute",
    left: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
    maxWidth: 500,
  },
  title: {
    fontWeight: 500,
    fontSize: "1.55rem",
    paddingBottom: 30,
    lineHeight: "2.11rem",
  },
  coloredTitle: {
    color: "#FF7900",
  },
  description: {
    fontSize: "1rem",
    lineHeight: "1.72rem",
  },
})

export interface Props {
  imagePath: string
}

type PropsAndStyles = Props & WithStyles<typeof styles>

const About = ({ classes, imagePath }: PropsAndStyles) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(maxHeight: 454, quality: 100) {
                  aspectRatio
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(
        image => image.node.relativePath === imagePath
      )

      return (
        <div className={classes.about}>
          <Img
            className={classNames(
              image?.node.childImageSharp.fluid.aspectRatio,
              classes.backgroundImage
            )}
            imgStyle={{
              objectFit: "contain",
              objectPosition: "left bottom",
            }}
            fluid={image?.node.childImageSharp.fluid}
          />

          <div className={classes.aboutContent}>
            <div className={classes.title}>
              <div className={classes.coloredTitle}>
                It all started in January 2020,
              </div>
              <div>
                when 4 friends allied themselves with a mind-blowing project!
              </div>
            </div>
            <div className={classes.description}>
              Located in the heart of Portugal, in Lisbon, we are a rising and
              vibrant mobile gaming studio that aspires to be the most player
              oriented company in the world. In less than 6 months the team grew
              from 4 to 10 members, producing quality titles at a fast pace.
            </div>
          </div>
        </div>
      )
    }}
  />
)

export default withStyles(styles)(About)
